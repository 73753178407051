<template>
  <div>
    <b-card class="chat-card">
      <div
        ref="chatWindow"
        class="chat-window"
      >
        <p
          v-if="messages.length === 0"
          class="text-center"
        >
          No messages yet
        </p>
        <div
          class="messages"
        >
          <div
            v-for="(message, index) in messages"
            :key="index"
            class="message"
            :class="message.userId === admin.id ? 'admin-message' : 'user-message'"
          >
            <template>
              <span
                v-if="kycInfo && message.userId !== admin.id"
                class="text-primary"
              >
                {{ kycInfo.applicantInfo.firstName }} {{ kycInfo.applicantInfo.lastName }}
              </span>
              <span>{{ message.message }}</span>
              <div
                v-if="message.fileName"
                class="file-wrapper"
              >
                <div
                  class="file"
                  @click="onViewFile(message.fileName)"
                >
                  <div class="file-icon">
                    <feather-icon
                      icon="FileIcon"
                      size="24"
                    />
                  </div>
                  <span>{{ formatLongStringOnStart(message.fileName, 15) }}</span>
                </div>
              </div>
              <span class="message-time">{{ formattedShortDate(message.updatedAt) }} {{ formattedShortTime(message.updatedAt) }}</span>
            </template>
          </div>
        </div>
      </div>
      <b-input-group class="mt-3">
        <b-form-input
          v-model="newMessage"
          placeholder="Type a message..."
          @keyup.enter="sendMessage"
        />
        <b-input-group-append>
          <b-button
            :disabled="newMessage.length === 0 || newMessage.length > maxSymbolsLimit || isSending"
            variant="primary"
            @click="sendMessage"
          >
            Send
          </b-button>
        </b-input-group-append>
      </b-input-group>
      <span
        class="symbols-count"
        :class="{'symbols-count-error': newMessage.length > maxSymbolsLimit}"
      >{{ newMessage.length }} / {{ maxSymbolsLimit }}</span>
    </b-card>
  </div>
</template>

<script>
import {
  BButton, BCard, BFormInput, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import axios from '@/plugins/axios'
import validationError from '@/mixins/validationError'
import {
  formattedShortDate, formattedDate, formattedShortTime, formatLongStringOnStart,
} from '@/tools/util'
import waitRequest from '@/mixins/waitRequest'
import checkErrorsAlert from '@/mixins/checkErrorsAlert'

export default {
  name: 'KYCChat',
  components: {
    BButton,
    BInputGroupAppend,
    BFormInput,
    BInputGroup,
    BCard,
  },
  mixins: [validationError, waitRequest, checkErrorsAlert],
  data() {
    return {
      messages: [
      ],
      newMessage: '',
      kycInfo: null,
      formattedShortDate,
      formattedDate,
      formattedShortTime,
      maxSymbolsLimit: 200,
      formatLongStringOnStart,
      messagesLength: 0,
      isSending: false,
    }
  },

  computed: {
    ...mapGetters({
      admin: 'auth/admin',
    }),
  },

  mounted() {
    this.initState()
    this.messagesPolling()
  },
  methods: {
    ...mapActions({
      fetchInfo: 'kyc/fetchInfo',
      getLink: 'kyc/getLink',
    }),

    async initState() {
      try {
        this.kycInfo = (await this.fetchInfo(this.$route.params.id)).data
        await this.getMessages()
        this.messagesLength = this.messages.length
        this.$nextTick(() => {
          this.scrollToBottom()
        })
      } catch (e) {
        this.checkErrors(e)
      }
    },
    scrollToBottom() {
      const { chatWindow } = this.$refs
      if (chatWindow) {
        chatWindow.scrollTop = chatWindow.scrollHeight
      }
    },

    async getMessages() {
      const data = await axios({ method: 'GET', url: `/kyc/${this.$route.params.id}/messages` }).then(response => response.data)
      this.messages = data.reverse()
    },

    async sendMessage() {
      if (this.newMessage.length === 0 || this.newMessage.length > this.maxSymbolsLimit) {
        return
      }
      this.isSending = true
      try {
        const data = {
          message: this.newMessage,
        }
        await axios({ method: 'POST', url: `/kyc/${this.$route.params.id}/messages`, data })
        await this.getMessages()
        this.newMessage = ''
        this.$nextTick(() => {
          this.scrollToBottom()
        })
      } catch (e) {
        this.checkErrorsAlert(e)
      } finally {
        this.isSending = false
      }
    },
    onViewFile(path) {
      this.waitRequest(async () => {
        await this.getLink({ path })
          .then(({ data }) => {
            window.open(data.url, '_blank')
          })
          .catch(this.checkErrorsAlert)
      })
    },

    messagesPolling() {
      setInterval(async () => {
        await this.getMessages()
        if (this.messages.length !== this.messagesLength) {
          this.$nextTick(() => {
            this.scrollToBottom()
          })
        }
      }, 30000)
    },
  },

}
</script>

<style scoped lang="scss">
.chat-card {
  max-width: 100vw;
  margin: auto;
}
.chat-window {
  height: 65vh;
  overflow-y: auto;
  padding: 10px;
}

.messages {
  display: flex;
  flex-direction: column;
}
.message {
  background: #37425e;
  padding: 4px 16px;
  border-radius: 8px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-width: 80%;

  &-time {
    font-size: 10px;
    color: gray;
    margin-top: 4px;
  }
}

.admin-message {
  align-self: flex-end;
  text-align: right;
  padding: 12px 16px;
}

.user-message {
  align-self: flex-start;
}

.symbols-count {
  font-size: 12px;
  color: gray;
  &-error {
    color: red
  }
}

.file-wrapper {
  padding-top: 4px;
  margin-top: 8px;
}

.file {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  background: #212738;
  padding: 4px;
  border-radius: 8px;
  font-size: 12px;
  max-width: 160px;
}
</style>
